var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c("md-card", { staticClass: "main-rodape" }, [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 rodape",
          },
          [
            _c("h5", [_vm._v("Fonte de dados: MobRadio Analytics")]),
            _vm._v("   "),
            _c("img", {
              staticClass: "logoRodape",
              attrs: { src: require("../../../../public/img/favicon.png") },
            }),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }