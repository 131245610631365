<template>
    <div class="md-layout">
        <md-card class="main-rodape">
            <div class="md-layout-item md-small-size-100 md-medium-size-100 rodape">
                <h5>Fonte de dados: MobRadio Analytics</h5> &nbsp;
                <img class="logoRodape" src="../../../../public/img/favicon.png">
            </div>
        </md-card>
    </div>
</template>

<script>
export default {
    name: 'rodape'
}
</script>

<style scoped>
    .main-rodape {
        padding: 20px;
    }
    h4 {
        font-weight: 500;

    }
    .rodape {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logoRodape {
        width: 18px !important;
        height: 18px !important;
        margin-top: 10px !important;
    }
    @media (min-width: 960px) {
        .rodape {
            display: flex;
            justify-content: center;
        }
    }
</style>

