var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _c("FilterSelector", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.deviceType && _vm.orderType,
              expression: "deviceType && orderType",
            },
          ],
          attrs: {
            filterForReport: true,
            buttonsToDisplay: [
              _vm.buttonEnum.YESTERDAY,
              _vm.buttonEnum.SEVEN_DAYS,
              _vm.buttonEnum.MONTH_TO_DATE,
              _vm.buttonEnum.CUSTOM,
            ],
            extraFunction: true,
            extraFunctionLabel: "IMPRIMIR",
            disableExtraFunction: this.tableData.length === 0,
          },
          on: {
            searchData: function ($event) {
              return _vm.getVotedPlataform()
            },
            extraFunction: function ($event) {
              return _vm.printReport()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "filters",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "md-layout" },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "md-layout-item md-size-100",
                          staticStyle: {
                            "font-size": "1rem",
                            "text-align": "left",
                            margin: "25px 0 10px 0",
                            padding: "0",
                            "font-weight": "100",
                          },
                        },
                        [_vm._v(" Plataforma e ordenação ")]
                      ),
                      _c(
                        "el-select",
                        {
                          staticClass:
                            "md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20",
                          staticStyle: { "padding-left": "0" },
                          attrs: { placeholder: "Plataforma" },
                          model: {
                            value: _vm.deviceType,
                            callback: function ($$v) {
                              _vm.deviceType = $$v
                            },
                            expression: "deviceType",
                          },
                        },
                        [
                          _c("el-option", { attrs: { value: "Android" } }, [
                            _vm._v("Android"),
                          ]),
                          _c("el-option", { attrs: { value: "Iphone" } }, [
                            _vm._v("iOS"),
                          ]),
                          _c("el-option", { attrs: { value: "Web" } }, [
                            _vm._v("Web"),
                          ]),
                          _c("el-option", { attrs: { value: "Windows" } }, [
                            _vm._v("Windows"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticClass:
                            "md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20",
                          staticStyle: { "padding-left": "0" },
                          attrs: { placeholder: "Ordenar por..." },
                          model: {
                            value: _vm.orderType,
                            callback: function ($$v) {
                              _vm.orderType = $$v
                            },
                            expression: "orderType",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            {
                              attrs: {
                                value: "percentual_likes",
                                label: "Curti",
                              },
                            },
                            [_vm._v("Curti")]
                          ),
                          _c(
                            "el-option",
                            {
                              attrs: {
                                value: "percentual_dislikes",
                                label: "Não curti",
                              },
                            },
                            [_vm._v("Não curti")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass:
          "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
      },
      [_c("Spinner")],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading",
          },
        ],
        staticClass: "md-layout",
        attrs: { id: "principal" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
          },
          [
            _c(
              "md-card",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.msgError,
                    expression: "msgError",
                  },
                ],
                staticStyle: { "text-align": "center" },
              },
              [
                _c("md-card-content", [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Sem informações pra serem exibidas."),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        this.tableData.length > 0
          ? _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
              },
              [
                _c(
                  "md-card",
                  [
                    _c(
                      "md-card-header",
                      {
                        staticClass: "md-card-header-icon md-card-header-blue",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-icon" },
                          [_c("md-icon", [_vm._v("thumbs_up_down")])],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "md-card-content",
                      [
                        _c(
                          "md-table",
                          {
                            staticClass:
                              "paginated-table table-striped table-hover",
                            attrs: { value: _vm.queriedData },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "md-table-row",
                                  fn: function ({ item }) {
                                    return _c(
                                      "md-table-row",
                                      {},
                                      [
                                        _c(
                                          "md-table-cell",
                                          {
                                            attrs: {
                                              "md-label": "Título",
                                              "md-sort-by": "title",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.title))]
                                        ),
                                        _c(
                                          "md-table-cell",
                                          {
                                            attrs: {
                                              "md-label": "Artista",
                                              "md-sort-by": "artist",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.artist))]
                                        ),
                                        _c(
                                          "md-table-cell",
                                          { attrs: { "md-label": "Curti" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  item.total_likes
                                                )
                                              ) +
                                                " (" +
                                                _vm._s(item.percentual_likes) +
                                                "%)"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "md-table-cell",
                                          {
                                            attrs: { "md-label": "Não Curti" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  item.total_dislikes
                                                )
                                              ) +
                                                " (" +
                                                _vm._s(
                                                  item.percentual_dislikes
                                                ) +
                                                "%)"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "md-table-cell",
                                          { attrs: { "md-label": "Total" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  item.total
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              3648989810
                            ),
                          },
                          [
                            _c(
                              "md-table-toolbar",
                              [
                                _c(
                                  "md-field",
                                  [
                                    _c("label", { attrs: { for: "pages" } }, [
                                      _vm._v("Por página"),
                                    ]),
                                    _c(
                                      "md-select",
                                      {
                                        attrs: { name: "pages" },
                                        model: {
                                          value: _vm.pagination.perPage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.pagination,
                                              "perPage",
                                              $$v
                                            )
                                          },
                                          expression: "pagination.perPage",
                                        },
                                      },
                                      _vm._l(
                                        _vm.pagination.perPageOptions,
                                        function (item) {
                                          return _c(
                                            "md-option",
                                            {
                                              key: item,
                                              attrs: {
                                                label: item,
                                                value: item,
                                              },
                                            },
                                            [_vm._v(" " + _vm._s(item) + " ")]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "md-card-actions",
                      { attrs: { "md-alignment": "space-between" } },
                      [
                        _c("div", {}, [
                          _c("p", { staticClass: "card-category" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.from + 1) +
                                " à " +
                                _vm._s(_vm.to) +
                                " de " +
                                _vm._s(_vm.total) +
                                " itens "
                            ),
                          ]),
                        ]),
                        _c("pagination", {
                          staticClass:
                            "pagination-no-border pagination-success",
                          attrs: {
                            "per-page": _vm.pagination.perPage,
                            total: _vm.total,
                          },
                          model: {
                            value: _vm.pagination.currentPage,
                            callback: function ($$v) {
                              _vm.$set(_vm.pagination, "currentPage", $$v)
                            },
                            expression: "pagination.currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("Rodape"),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c("div", { staticClass: "md-layout", attrs: { id: "impressao" } }, [
      _vm._m(0),
      _c("div", { staticClass: "titleRight" }, [
        _c("b", { staticClass: "nameRadio" }, [
          _vm._v(
            _vm._s(_vm.nameRadioReport ? _vm.nameRadioReport : "MobRadio")
          ),
        ]),
        _vm._v("   "),
        _c("img", {
          staticClass: "logoRadio",
          attrs: { src: _vm.logo, alt: "" },
        }),
      ]),
      _c("div", [
        _c("span", [
          _vm._v(
            "Período: " +
              _vm._s(_vm.periodStart) +
              " até: " +
              _vm._s(_vm.periodEnd) +
              " "
          ),
        ]),
      ]),
      this.tableData.length > 0
        ? _c("div", { staticClass: "contentBody impressao" }, [
            _c("div", { staticClass: "divTable" }, [
              _c("h4", [_vm._v("Votação")]),
              _c(
                "div",
                {
                  staticClass: "md-content md-table md-theme-default",
                  attrs: { "table-header-color": "blue" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-content md-table-content md-scrollbar md-theme-default",
                    },
                    [
                      _c("table", [
                        _vm._m(1),
                        _vm._m(2),
                        _c(
                          "tbody",
                          _vm._l(_vm.tableData, function (item, index) {
                            return _c(
                              "tr",
                              { key: index, staticClass: "md-table-row" },
                              [
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [_vm._v(" " + _vm._s(item.artist) + " ")]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatNumber")(
                                              item.total_likes
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(item.percentual_likes) +
                                          "%) "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatNumber")(
                                              item.total_dislikes
                                            )
                                          ) +
                                          " (" +
                                          _vm._s(item.percentual_dislikes) +
                                          "%) "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("td", { staticClass: "md-table-cell" }, [
                                  _c(
                                    "div",
                                    { staticClass: "md-table-cell-container" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("formatNumber")(item.total)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../../public/img/logo-mobradio-black.png"),
          alt: "",
        },
      }),
      _c("h1", [_vm._v("Relatório de Músicas Votadas por Plataforma")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Título"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Artista"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Curti"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Não Curti"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Total"),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tfoot", { staticClass: "report-footer" }, [
      _c("tr", [
        _c("td", { attrs: { colspan: "6" } }, [
          _vm._v(" Fonte de dados: MobRadio Analytics   "),
          _c("img", {
            staticClass: "footerIcon",
            attrs: { src: require("../../../../public/img/favicon.png") },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }