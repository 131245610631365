<template>
	<div>
		<div class="md-layout-item md-size-100">
			<FilterSelector
				v-show="deviceType && orderType"
				@searchData="getVotedPlataform()"
				:filterForReport="true"
				:buttonsToDisplay="[
					buttonEnum.YESTERDAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.MONTH_TO_DATE,
					buttonEnum.CUSTOM,
				]"
				@extraFunction="printReport()"
				:extraFunction="true"
				:extraFunctionLabel="'IMPRIMIR'"
				:disableExtraFunction="this.tableData.length === 0"
			>
				<template #filters>
					<div class="md-layout">
						<h3
							class="md-layout-item md-size-100"
							style="
								font-size: 1rem;
								text-align: left;
								margin: 25px 0 10px 0;
								padding: 0;
								font-size: 1rem;
								font-weight: 100;
							"
						>
							Plataforma e ordenação
						</h3>
						<el-select
							style="padding-left: 0"
							class="md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20"
							placeholder="Plataforma"
							v-model="deviceType"
						>
							<el-option value="Android">Android</el-option>
							<el-option value="Iphone">iOS</el-option>
							<el-option value="Web">Web</el-option>
							<el-option value="Windows">Windows</el-option>
						</el-select>

						<el-select
							style="padding-left: 0"
							class="md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20"
							placeholder="Ordenar por..."
							v-model="orderType"
						>
							<el-option value="percentual_likes" label="Curti"
								>Curti</el-option
							>
							<el-option value="percentual_dislikes" label="Não curti"
								>Não curti</el-option
							>
						</el-select>
					</div>
				</template>
			</FilterSelector>
		</div>

		<div
			v-show="loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<Spinner />
		</div>
		<div v-show="!loading" id="principal" class="md-layout">
			<div
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<md-card style="text-align: center" v-show="msgError">
					<md-card-content>
						<label for="">Sem informações pra serem exibidas.</label>
					</md-card-content>
				</md-card>
			</div>
			<div
				v-if="this.tableData.length > 0"
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<md-card>
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<div class="card-icon">
							<md-icon>thumbs_up_down</md-icon>
						</div>
					</md-card-header>
					<md-card-content>
						<md-table
							:value="queriedData"
							class="paginated-table table-striped table-hover"
						>
							<md-table-toolbar>
								<md-field>
									<label for="pages">Por página</label>
									<md-select v-model="pagination.perPage" name="pages">
										<md-option
											v-for="item in pagination.perPageOptions"
											:key="item"
											:label="item"
											:value="item"
										>
											{{ item }}
										</md-option>
									</md-select>
								</md-field>
							</md-table-toolbar>

							<md-table-row slot="md-table-row" slot-scope="{ item }">
								<!-- {{item}} -->
								<md-table-cell md-label="Título" md-sort-by="title">{{
									item.title
								}}</md-table-cell>
								<md-table-cell md-label="Artista" md-sort-by="artist">{{
									item.artist
								}}</md-table-cell>
								<md-table-cell md-label="Curti"
									>{{ item.total_likes | formatNumber }} ({{
										item.percentual_likes
									}}%)</md-table-cell
								>
								<md-table-cell md-label="Não Curti"
									>{{ item.total_dislikes | formatNumber }} ({{
										item.percentual_dislikes
									}}%)</md-table-cell
								>
								<!-- <md-table-cell md-label="Já deu">{{ item.total_xx_dislikes | formatNumber }}</md-table-cell> -->
								<md-table-cell md-label="Total">{{
									item.total | formatNumber
								}}</md-table-cell>
							</md-table-row>
						</md-table>
					</md-card-content>
					<md-card-actions md-alignment="space-between">
						<div class="">
							<p class="card-category">
								{{ from + 1 }} à {{ to }} de {{ total }} itens
							</p>
						</div>
						<pagination
							class="pagination-no-border pagination-success"
							v-model="pagination.currentPage"
							:per-page="pagination.perPage"
							:total="total"
						>
						</pagination>
					</md-card-actions>
				</md-card>

				<Rodape />
			</div>
		</div>
		<div id="impressao" class="md-layout">
			<div>
				<img src="../../../../public/img/logo-mobradio-black.png" alt="" />
				<h1>Relatório de Músicas Votadas por Plataforma</h1>
			</div>
			<div class="titleRight">
				<b class="nameRadio">{{
					nameRadioReport ? nameRadioReport : "MobRadio"
				}}</b>
				&nbsp;
				<img class="logoRadio" :src="logo" alt="" />
			</div>
			<div>
				<span>Período: {{ periodStart }} até: {{ periodEnd }} </span>
			</div>

			<div v-if="this.tableData.length > 0" class="contentBody impressao">
				<div class="divTable">
					<h4>Votação</h4>
					<div
						class="md-content md-table md-theme-default"
						table-header-color="blue"
					>
						<div
							class="md-content md-table-content md-scrollbar md-theme-default"
						>
							<table>
								<thead>
									<tr>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Título</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Artista</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Curti</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Não Curti</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Total</div>
											</div>
										</th>
									</tr>
								</thead>
								<tfoot class="report-footer">
									<tr>
										<td colspan="6">
											Fonte de dados: MobRadio Analytics &nbsp;
											<img
												class="footerIcon"
												src="../../../../public/img/favicon.png"
											/>
										</td>
									</tr>
								</tfoot>
								<tbody>
									<tr
										class="md-table-row"
										v-for="(item, index) in tableData"
										:key="index"
									>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.title }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.artist }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.total_likes | formatNumber }} ({{
													item.percentual_likes
												}}%)
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.total_dislikes | formatNumber }} ({{
													item.percentual_dislikes
												}}%)
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.total | formatNumber }}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import FilterSelector from "../Components/FilterSelector/FilterSelector.vue";
import filters from "../Components/FilterSelector/filters";
import buttonEnum from "../Components/FilterSelector/buttonEnum";
import Spinner from "../../../components/Icons/Spinner";
import Rodape from "./Rodape.vue";
import { Pagination } from "@/components";
import models from "../../../routes/models.js";
import jwt_decode from "jwt-decode";
import { DatePicker } from "element-ui";
const numeral = require("numeral");
numeral.locale("pt-br");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});
import { ChartCard } from "@/components";
import selectRadio from "../../../utils/events/select-radio.js";
import mixpanelAdapter from "../../../utils/events/mixpanelAdapter";

export default {
	components: {
		ChartCard,
		Spinner,
		Rodape,
		[DatePicker.name]: DatePicker,
		Pagination,
		FilterSelector,
	},
	computed: {
		queriedData() {
			let result = this.tableData;
			if (this.searchedData.length > 0) {
				result = this.searchedData;
			}
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0
				? this.searchedData.length
				: this.tableData.length;
		},
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			nameRadioReport: jwt_decode(localStorage.getItem("APP_01")).radio.name,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,
			loading: false,
			deviceType: null,
			orderType: null,
			currentSort: "title",
			currentSortOrder: "asc",
			pagination: {
				perPage: 50,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			searchQuery: "",
			propsToSearch: ["Título", "artist", "age"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			widthPicker: 100 + "%",
			periodStart: null,
			periodEnd: null,
			logo: "",
			btnImprimir: true,
			disabledButton: true,
			msgError: false,
		};
	},
	methods: {
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].localeCompare(b[sortBy]);
				}
				return b[sortBy].localeCompare(a[sortBy]);
			});
		},
		getVotedPlataform() {
			this.tableData = [];
			this.loading = true;
			const formatDateStart = new Date(filters.startDate);
			const formatDateEnd = new Date(filters.endDate);
			let dateStart =
				formatDateStart.getFullYear() +
				"-" +
				(formatDateStart.getMonth() + 1) +
				"-" +
				formatDateStart.getDate() +
				" 00:00:00";
			let dateEnd =
				formatDateEnd.getFullYear() +
				"-" +
				(formatDateEnd.getMonth() + 1) +
				"-" +
				formatDateEnd.getDate() +
				" 23:59:59";
			this.periodStart = new Date(filters.startDate).toLocaleDateString(
				"pt-BR"
			);
			this.periodEnd = new Date(filters.endDate).toLocaleDateString("pt-BR");
			models()
				.get(
					`music/getVotedPlataform?appKey=${this.appKey}&startDate=${dateStart}&endDate=${dateEnd}&filterKey=${this.orderType}&plataformType=${this.deviceType}`
				)
				.then((resp) => {
					if (resp.data.length > 0) {
						this.btnImprimir = false;
						this.msgError = false;
						for (let item of resp.data) {
							this.tableData.push({
								title: item.title,
								artist: item.artist,
								total_likes: item.total_likes,
								total_dislikes: item.total_dislikes,
								percentual_likes: Math.round(
									(item.total_likes * 100) / item.total
								),
								percentual_dislikes: Math.round(
									(item.total_dislikes * 100) / item.total
								),
								total: item.total,
							});
						}
					} else {
						this.btnImprimir = true;
						this.msgError = true;
					}
					this.loading = false;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		printReport() {
			window.print();
		}
	},

	mounted() {
		if (jwt_decode(localStorage.getItem("APP_01")).radio) {
			this.appKey = jwt_decode(localStorage.getItem("APP_01")).radio.key;
			this.logo = jwt_decode(localStorage.getItem("APP_01")).radio.logo;
			this.deviceType = "Android";
			this.orderType = "percentual_likes";
		}

		selectRadio.$on("selectRadio", (payload) => {
			this.tableData = [];
			this.appKey = payload.key;
			this.serverKey = payload.server_key;
			this.nameRadioReport = payload.name;
			this.logo = payload.logo;
			this.btnImprimir = true;
			this.deviceType = "Android";
			this.orderType = "percentual_likes";

			this.getVotedPlataform();
		});
	},
	watch: {
		searchQuery(value) {
			let result = this.tableData;
			if (value !== "") {
				result = this.fuseSearch.search(this.searchQuery);
			}
			this.searchedData = result;
		},
		deviceType() {
			this.getVotedPlataform();
		},
		orderType() {
			this.getVotedPlataform();
		},
	},
};
</script>
<style>
#impressao {
	display: none;
}

@page {
	size: auto;
	margin: 10mm 10mm 10mm 10mm;
}

@media print {
	#principal {
		display: none;
	}

	#impressao {
		display: block;
		margin-top: -80px;
	}

	tr {
		page-break-inside: avoid;
	}

	table.report-container {
		page-break-after: always !important;
	}

	thead.report-header {
		display: table-header-group !important;
	}

	tfoot.report-footer {
		display: table-footer-group !important;
		text-align: center;
		font-size: 12pt;
	}

	body {
		font-size: 8pt;
	}

	img {
		width: 160px;
		height: 160px;
	}

	h1 {
		font-size: 18pt;
	}

	span {
		font-size: 12pt;
	}

	footer {
		text-align: center;
		font-size: 12pt;
	}

	.md-toolbar-section-start {
		display: none;
	}

	.titleRight {
		float: right;
		margin-top: -80px;
	}

	.nameRadio {
		font-size: 18pt;
	}

	.logoRadio {
		width: 48px;
		height: 48px;
		border-radius: 25px;
		margin-top: -10px;
	}

	.contentBody {
		margin-top: 50px;
	}

	.divTable {
		border: 1px solid #d2d2d2;
		padding: 10px;
		margin-bottom: 20px;
	}

	.footerIcon {
		width: 16px;
		height: 16px;
		margin-top: -5px;
	}
}
</style>
